import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, from } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { LookUpComponent } from '@request/look-up/look-up.component';

import { TAGLookUpEntity } from './tag-lookup-entity';
import { PartsGQL } from '@shared/apollo/queries/parts';

@Injectable({
  providedIn: 'root',
})
export class LookUpService {
  dynamicData: any[] = [];
  dialogRef_: MatDialogRef<any> | undefined;

  constructor(
    public dialog: MatDialog,
    private partsService: PartsGQL  
  ) {}
  checkPartsAvailabilityQuietly(filter: string | undefined): Observable<any> {
    return this.partsService
      .watch({
        filter: filter ? `Equipment_ID eq '${filter}'` : '',  
        top: 1,  
        skip: 0,
      })
      .valueChanges.pipe(
        take(1),
        map(result => result.data.parts)
      );
  }

  lookUp<T>(
    entity: TAGLookUpEntity, 
    filteredValue?: string | null,
    eventHandlers?: { 
      barcodeScanned?: (code: string) => void,
      skipDialog?: boolean 
    }
  ): Observable<Record<any, any> | undefined> {
    if (eventHandlers?.skipDialog) {
      return this.checkPartsAvailabilityQuietly(filteredValue || undefined);
    }

    return new Observable<Record<any, any> | undefined>((observer) => {
      const dialogRef = this.dialog.open(LookUpComponent, {
        panelClass: ['Zoom-out', 'animated', 'dynamic-look-up'],
        data: { entity, filteredValue },
        position: { top: '100px' },
      });

      if (eventHandlers) {
        const instance = dialogRef.componentInstance;
        if (eventHandlers.barcodeScanned) {
          instance.barcodeScanned.subscribe(eventHandlers.barcodeScanned);
        }
      }

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          observer.next(result);
          observer.complete();
        });
    });
  }
}
